const tournamentVarArray = [
  {
    tournamentId: 'columbusday2021',
    tournamentName: 'Columbus Day 2021',
    tournamentDates: ['10-11-2021'],
    tournamentDatesFull: 'October 11th, 2021',
    tournamentDescription: '',
    tournamentYear: 2021,
    tournamentLocation: 'Hershey, Pennsylvania',
    tournamentDivisions: ['10U', '12UA1', '12UA2', '14U', '16U'],
    seasonYear: '2021-2022',
    paypalButtonId: 'RYLBCZ354UMPW',
    paypalButtonIdDev: 'L95ZNT94EXWPQ',
    total: '$140',
    totalNum: 140,
    totalNumString: '140',
    retired: true
  },
  {
    tournamentId: 'mlk2022',
    tournamentName: 'MLK Mid-Florida Meltdown',
    tournamentDescription: '',
    tournamentDates: ['01-14-2022', '01-17-2022'],
    tournamentDatesFull: 'January 14th-17th, 2022',
    tournamentYear: 2022,
    tournamentLocation: 'Wesley Chapel, Florida',
    tournamentDivisions: ['10U', '12UA1', '12UA2', '14U', '16U'],
    seasonYear: '2021-2022',
    paypalButtonId: '8W4UWN3M7ZSWU',
    paypalButtonIdDev: '3JE9J9BGRWUWA',
    total: '$195',
    totalNum: 195,
    totalNumString: '195',
    retired: true
  },
  {
    tournamentId: 'thanksgiving2021',
    tournamentName: 'Thanksgiving on the Beach',
    tournamentDates: ['11-26-2021', '11-28-2021'],
    tournamentDatesFull: 'November 26th-28th, 2021',
    tournamentDescription: '',
    tournamentYear: 2021,
    tournamentLocation: 'Clearwater Beach, Florida',
    tournamentDivisions: ['10U', '12UA2', '14U', '16U'],
    seasonYear: '2021-2022',
    paypalButtonId: '8AQ5RDD8VLWUJ',
    paypalButtonIdDev: 'FFGBJUAC7UT4Q',
    total: '$195',
    totalNum: 195,
    totalNumString: '195',
    retired: true
  },
  {
    tournamentId: 'thanksgiving2024',
    tournamentName: 'Thanksgiving on the Beach',
    tournamentDates: ['11-29-2024', '12-01-2024'],
    tournamentDatesFull: 'November 29th-December 1st 2024',
    tournamentDescription: '',
    tournamentYear: 2024,
    tournamentLocation: 'Clearwater Beach, Florida',
    tournamentDivisions: ['16U'],
    seasonYear: '2024-2025',
    paypalButtonId: 'SM3C63Z9AH728',
    paypalButtonIdDev: '',
    total: '$245',
    totalNum: 245,
    totalNumString: '245',
    retired: false
  },
  {
    tournamentId: '10udecfeb2024',
    tournamentName: 'Dec & Feb - 10U',
    tournamentDates: ['12-01-2024', '02-01-2025'],
    tournamentDatesFull: 'December 2024, February 2025',
    tournamentDescription: '',
    tournamentYear: 2024,
    tournamentLocation: 'Savannah, GA in Dec, Buffalo NY in Feb',
    tournamentDivisions: ['10U'],
    seasonYear: '2024-2025',
    paypalButtonId: '85S93PRRCMGQA',
    paypalButtonIdDev: '',
    total: '$300',
    totalNum: 300,
    totalNumString: '300',
    retired: false
  },
  {
    tournamentId: 'presidentsday2022',
    tournamentName: 'Presidents Day MyHockey Tournament',
    tournamentDates: ['02-11-2022'],
    tournamentDatesFull: 'February 11th, 2022',
    tournamentDescription: '',
    tournamentYear: 2022,
    tournamentLocation: 'Minnesota',
    tournamentDivisions: ['14U', '16U'],
    seasonYear: '2021-2022',
    paypalButtonId: 'HFKZSHT7TV69J',
    paypalButtonIdDev: 'S2F86EV2FVXPW',
    paypalButtonIdArray: ['HFKZSHT7TV69J', '0'],
    paypalButtonIdDevArray: ['S2F86EV2FVXPW', '0'],
    total: '$50',
    totalNum: 50,
    totalNumString: '50',
    totalArray: ['$50', '$0'],
    totalNumArray: [50, 0],
    totalNumStringArray: ['50', '0'],
    retired: true
  },
  {
    tournamentId: 'sahof10ugold2022',
    tournamentName: 'SAHOF State Championships 10u Gold Division',
    tournamentDates: ['03-11-2022', '03-13-2022'],
    tournamentDatesFull: 'March 11th-13th, 2022',
    tournamentDescription: '',
    tournamentYear: 2022,
    tournamentLocation: 'Palm Beach Skate Zone, Lake Worth, FL',
    tournamentDivisions: ['10U'],
    seasonYear: '2021-2022',
    paypalButtonId: 'KJ662MQVYVEPW',
    paypalButtonIdDev: 'FEJ4SW2DBBVXQ',
    total: '$150',
    totalNum: 150,
    totalNumString: '150',
    retired: true
  },
  {
    tournamentId: 'columbusdaymlk2022',
    tournamentName: "Columbus Day/TCS Hockey and MLK/MyHockey Combined",
    tournamentDates: ['10-07-2023','10-11-2022','01-14-2023','01-16-2013'],
    tournamentDatesFull: 'Oct 7, 2022 - October 9, 2022 (Columbus) and Jan 14, 2023-Jan 16, 2023 (MLK)',
    tournamentDescription: 'Combined Columbus Day and MLK Tournament Registration',
    tournamentYear: 2022,
    tournamentLocation: 'Knoxville, TN (Columbus) and Philadelphia, PA (MLK)',
    tournamentDivisions: ['8U', '10U', '12U', '14U', '16U'],
    seasonYear: '2022-2023',
    paypalButtonId: 'XXX',
    paypalButtonIdDev: 'YYY',
    paypalButtonIdArray: ['VQ8EYWY5G8CD6', 'CULA6NYQXDLL2', 'CULA6NYQXDLL2', 'CULA6NYQXDLL2', 'D6VCXLVKBYTG6'],
    paypalButtonIdDevArray: ['TGJ5WJ99NP62J', 'GDSHAWY8YTZAL', 'GDSHAWY8YTZAL', 'GDSHAWY8YTZAL', '3P93DCZQZEVA4'],
    total: '$50',
    totalNum: 50,
    totalNumString: '50',
    totalArray: ['$263', '$437', '$437', '$437', '$135'],
    totalNumArray: [280, 465, 465, 465, 144],
    totalNumStringArray: ['263', '437', '437', '437', '135'],
    retired: true
  },
  {
    tournamentId: 'columbusday2023',
    tournamentName: "Columbus Day",
    tournamentDates: ['10-09-2023'],
    tournamentDatesFull: 'Oct 9th, 2023',
    tournamentDescription: '',
    tournamentYear: 2023,
    tournamentLocation: 'Palm Beach, Florida',
    tournamentDivisions: ['10UWhite', '10UBlack', '12UBlack', '12UOrange', '14UWhite','14UOrange'],
    seasonYear: '2023-2024',
    paypalButtonId: 'XXX',
    paypalButtonIdDev: 'YYY',
    paypalButtonIdArray: ['NS9PZH6CGQMWS', 'QCCLWTWJKMTLN', '2W2ENC7C8SXW4', '7Z6CK5LWK97YY', 'D8GHPHWNGES8N','FR5QPBEAN8Z8A'],
    paypalButtonIdDevArray: ['NS9PZH6CGQMWS', 'QCCLWTWJKMTLN', '2W2ENC7C8SXW4', '7Z6CK5LWK97YY', 'D8GHPHWNGES8N','FR5QPBEAN8Z8A'],
    total: '$50',
    totalNum: 50,
    totalNumString: '50',
    totalArray: ['$255', '$255', '$205', '$285', '$225','$225'],
    totalNumArray: [255, 255, 205, 285, 225,225],
    totalNumStringArray: ['255', '255', '205', '285', '225','225'],
    retired: false
  },
  {
    tournamentId: 'mlk2023',
    tournamentName: "Martin Luther King Day",
    tournamentDates: ['01-15-2024'],
    tournamentDatesFull: 'January 15th, 2024',
    tournamentDescription: '',
    tournamentYear: 2023,
    tournamentLocation: '10U Yellow, 12U Blue - Orlando ' +
      '\n' +
      '10U Blue - Ellington' +
      '\n' +
      '12U Yellow, 14U Blue - Tampa' +
      '\n' +
      '14U Yellow - Ann Arbor',
    tournamentDivisions: ['10UWhite', '10UBlack', '12UBlack', '12UOrange', '14UWhite','14UOrange'],
    seasonYear: '2023-2024',
    paypalButtonId: 'XXX',
    paypalButtonIdDev: 'YYY',
    paypalButtonIdArray: ['4N45JZN2B2S6W', 'K2LHF735VM7UY', '4VNFNVLPZPSV6', 'SRMLU5JNK7TSL', 'TVXRTSG8V5YP4','MB3E8PPGTR7FG'],
    paypalButtonIdDevArray: ['4N45JZN2B2S6W', 'K2LHF735VM7UY', '4VNFNVLPZPSV6', 'SRMLU5JNK7TSL', 'TVXRTSG8V5YP4','MB3E8PPGTR7FG'],
    total: '$50',
    totalNum: 50,
    totalNumString: '50',
    totalArray: ['$255', '$255', '$205', '$285', '$225','$225'],
    totalNumArray: [255, 255, 205, 285, 225,225],
    totalNumStringArray: ['255', '255', '205', '285', '225','225'],
    retired: false,
    expireDateFull: '01/09/2024 11:59:00 pm',
    expireDateParsed: 1704844740000,
  },
  {
    tournamentId: 'presidentsday2023',
    tournamentName: "Jacksonville Presidents Day Tournament",
    tournamentDates: ['02-18-2023','02-19-2023','02-20-2023'],
    tournamentDatesFull: 'February 18th-20th 2023',
    tournamentDescription: '',
    tournamentYear: 2022,
    tournamentLocation: 'Jacksonville, Florida',
    tournamentDivisions: ['8U1','8U2', '10U'],
    seasonYear: '2022-2023',
    paypalButtonId: 'BQY7449KFSJBG',
    paypalButtonIdDev: 'T9XWRSKSNE8GY',
    total: '$155',
    totalNum: 155,
    totalNumString: '155',
    retired: true
  },
  {
    tournamentId: 'lakeplacid2024TEST', // these are low-cost buttons for testing. The sandbox no longer returns the proper URL with the POST command we send it.
    tournamentName: "Lake Placid '24",
    tournamentDates: ['02-21-2024','02-22-2024','02-23-2024', '02-24-2024', '02-25-2024'],
    tournamentDatesFull: 'February 21st-25th 2024',
    tournamentDescription: ``,
    tournamentYear: 2023,
    tournamentLocation: 'Tampa, Florida',
    tournamentDivisions: ['14UOrange (Pay in Full)', '14UOrange (Deposit)'],
    seasonYear: '2023-2024',
    paypalButtonIdDev: '',
    paypalButtonId: '',
    paypalBalanceButtonIdDev: '',
    paypalBalanceButtonId: 'GZPTCRHAF9ZCL',
    paypalButtonIdArray: ['8DXXVJRD84DQY', 'UW24W8RXWM9JW'],
    paypalButtonIdDevArray: ['8DXXVJRD84DQY', 'UW24W8RXWM9JW'],
    total: '$5',
    totalNum: 5,
    totalNumString: '5',
    totalArray: ['$5 (Pay in Full)', '$2 Deposit ($3 Due by 01/15/2024)'],
    totalNumArray: [5, 2],
    totalNumStringArray: ['5', '2'],
    downPaymentSupport: true,
    jacketSizeRequired: true,
    sweatshirtSizeRequired: true,
    tshirtSizeRequired: true,
    jacketSizeOptional: false,
    sweatshirtSizeOptional: false,
    tshirtSizeOptional: false,
    retired: true
  },
  {
    tournamentId: 'lakeplacid2024',
    tournamentName: "Lake Placid '24",
    tournamentDates: ['02-21-2024','02-22-2024','02-23-2024', '02-24-2024', '02-25-2024'],
    tournamentDatesFull: 'February 21st-25th 2024',
    tournamentDescription: `14U Orange Only`,
    tournamentYear: 2023,
    tournamentLocation: 'Tampa, Florida',
    tournamentDivisions: ['14UOrange (Pay in Full)', '14UOrange (Deposit)'],
    seasonYear: '2023-2024',
    paypalButtonIdDev: '',
    paypalButtonId: '',
    paypalBalanceButtonId: 'FDEDRH2MURD2J',
    paypalButtonIdArray: ['5PX3DDR6W2FXA', 'JAGKU58YN972Q'],
    paypalButtonIdDevArray: ['B5BSER74RCPQY', '43PKZ6TPC7EGQ'],
    total: '$695',
    totalNum: 695,
    totalNumString: '695',
    totalArray: ['$695 (Pay in Full)', '$250 Deposit (+$445 Due by 01/15/2024)'],
    totalNumArray: [695, 250],
    totalNumStringArray: ['695', '250'],
    downPaymentSupport: true,
    jacketSizeRequired: true,
    sweatshirtSizeRequired: true,
    tshirtSizeRequired: true,
    jacketSizeOptional: false,
    sweatshirtSizeOptional: false,
    tshirtSizeOptional: false,
    retired: true
  },
  {
    tournamentId: 'lakeplacid2025',
    tournamentName: "Lake Placid '25",
    tournamentDates: ['02-20-2025','02-21-2025','02-22-2025', '02-23-2025'],
    tournamentDatesFull: 'February 20th-23rd 2025',
    tournamentDescription: `14U  Only`,
    tournamentYear: 2024,
    tournamentLocation: 'Tampa, Florida',
    tournamentDivisions: ['14U (Pay in Full)', '14U (Deposit)'],
    seasonYear: '2024-2025',
    paypalButtonIdDev: '',
    paypalButtonId: '',
    paypalBalanceButtonId: 'QM3K3QBFKSFXQ',
    paypalButtonIdArray: ['8CSWXY3DG82HS', 'AJHMHGW8V96AA'],
    paypalButtonIdDevArray: ['B5BSER74RCPQY', '43PKZ6TPC7EGQ'],
    total: '$695',
    totalNum: 695,
    totalNumString: '695',
    totalArray: ['$695 (Pay in Full)', '$250 Deposit (+$445 Due by 01/15/2025)'],
    totalNumArray: [695, 250],
    totalNumStringArray: ['695', '250'],
    downPaymentSupport: true,
    jacketSizeRequired: true,
    sweatshirtSizeRequired: false,
    tshirtSizeRequired: false,
    jacketSizeOptional: false,
    sweatshirtSizeOptional: false,
    tshirtSizeOptional: false,
    retired: false
  },
  {
    tournamentId: 'decholiday2324',
    tournamentName: "December '24",
    tournamentDates: ['12-01-2023','12-02-2023','12-03-2023'],
    tournamentDatesFull: 'December 1st-3rd, 2023',
    tournamentDescription: '10U Blue Only',
    tournamentYear: 2023,
    tournamentLocation: 'Jacksonville, Florida',
    tournamentDivisions: ['10UBlue'],
    seasonYear: '2023-2024',
    paypalButtonId: 'KW2BHSZCVA5XU',
    paypalButtonIdDev: '',
    total: '$185',
    totalNum: 185,
    totalNumString: '185',
    retired: false,
    expireDateFull: '12/01/2023 11:59:00 pm',
    expireDateParsed: 1701475140000,
  },
  {
    tournamentId: 'presidentsday2024',
    tournamentName: "Presidents Day Tournament",
    tournamentDates: ['02-19-2024'],
    tournamentDatesFull: 'February 19th 2024',
    tournamentDescription: '',
    tournamentYear: 2023,
    tournamentLocation: '8U Blue/Yellow, 10U Yellow - Jacksonville, FL' +
      '\n' +
      '10U Blue - Athens, GA' +
      '\n' +
      '14U Yellow - Washington DC',
    tournamentDivisions: ['8UBlue', '8UYellow', '10UYellow', '10UBlue', '14UYellow'],
    seasonYear: '2023-2024',
    paypalButtonId: '',
    paypalButtonIdArray: ['BUKFVPY9FQH7N', 'ANWB2FFRAM798', 'VQEFEWH8YSPQL', '2VQ8HNKW2AEGA', 'ANP4FQ8GE947L'],
    paypalButtonIdDevArray: ['', '', '', '', '',''],
    total: '$50',
    totalNum: 50,
    totalNumString: '50',
    totalArray: ['$80', '$80', '$255', '$255', '$225'],
    totalNumArray: [80, 80, 255, 255, 225],
    totalNumStringArray: ['80', '80', '255', '255', '225'],
    retired: true,
    expireDateFull: '02/15/2024 11:59:00 pm',
    expireDateParsed: 1708041540000,
  },
  {
    tournamentId: 'yetis2024',
    tournamentName: "Yetis Tournament '24",
    tournamentDates: ['01-01-2024'],
    tournamentDatesFull: 'Dates TBD',
    tournamentDescription: '',
    tournamentYear: 2024,
    tournamentLocation: 'Location TBD',
    tournamentDivisions: ['10U', '12U', '14U'],
    seasonYear: '2024-2025',
    paypalButtonId: '6KHVZ3ZN3NXRG',
    paypalButtonIdDev: '',
    total: '$802',
    totalNum: 802,
    totalNumString: '802',
    retired: true,
    expireDateFull: '01/01/2024 11:59:00 pm',
    expireDateParsed: 1704153540000,
  },
  {
    tournamentId: 'state12u2024',
    tournamentName: "State (12U)",
    tournamentDates: ['02-23-2024','02-24-2024','02-25-2024'],
    tournamentDatesFull: 'February 23rd-25th 2024',
    tournamentDescription: 'Florida Bulldogs State Championships!',
    tournamentYear: 2023,
    tournamentLocation: 'Palm Beach, Florida',
    tournamentDivisions: ['12U'],
    seasonYear: '2023-2024',
    paypalButtonId: 'KUADH2KV7QBFC',
    paypalButtonIdDev: '',
    total: '$175',
    totalNum: 175,
    totalNumString: '175',
    retired: false,
    expireDateFull: '02/22/2024 11:59:00 pm',
    expireDateParsed: 1708646340000,
  },
  {
    tournamentId: 'state14u2024',
    tournamentName: "State (14U)",
    tournamentDates: ['03-01-2024','03-02-2024','03-03-2024'],
    tournamentDatesFull: 'March 1st-3rd 2024',
    tournamentDescription: 'Florida Bulldogs State Championships!',
    tournamentYear: 2023,
    tournamentLocation: 'Orlando Ice Den',
    tournamentDivisions: ['14U'],
    seasonYear: '2023-2024',
    paypalButtonId: '5PAM5SJ8LPCKJ',
    paypalButtonIdDev: '',
    total: '$225',
    totalNum: 225,
    totalNumString: '225',
    retired: false,
    expireDateFull: '02/29/2024 11:59:00 pm',
    expireDateParsed: 1709251140000,
  },
  {
    tournamentId: 'yetis10u2024',
    tournamentName: "Yetis 10U Tournament",
    tournamentDates: ['04-12-2024','04-14-2024'],
    tournamentDatesFull: 'April 12th-14th 2024',
    tournamentDescription: '',
    tournamentYear: 2024,
    tournamentLocation: 'Clearwater, FL',
    tournamentDivisions: ['10U'],
    seasonYear: '2024-2025',
    paypalButtonId: 'NERYQFEBH79AQ',
    paypalButtonIdDev: '',
    total: '$233',
    totalNum: 233,
    totalNumString: '233',
    retired: false,
  },
  {
    tournamentId: 'columbusdayandmlk2024',
    tournamentName: "Columbus Day '24 + MLK '25",
    tournamentDates: ['10-10-2024','10-11-2024','10-12-2024','10-13-2024','01-17-2025','01-18-2025','01-19-2025','01-20-2025'],
    tournamentDatesFull: 'October 10th-13th 2024 and January 17th-20th 2025',
    tournamentDescription: 'Combined Columbus Day and Martin Luther King Day Tournaments. \n\n8U is for MLK Day Only.\n\nNote: Monthly installments supported via Paypal - choose "Pay in 4" or "Pay Monthly".',
    tournamentYear: 2024,
    tournamentLocation: 'Palm Beach, Florida and Las Vegas, Nevada',
    tournamentDivisions: ['8U', '10U', '12U', '14U', '16U'],
    seasonYear: '2024-2025',
    paypalButtonId: 'XXX',
    paypalButtonIdDev: 'YYY',
    paypalButtonIdArray: ['LZ4SYE743SZF8', 'HR9634R5UFUCG', 'KHJ4VWEK9PLVL', 'XSZL6A57D34MW', '65NXSVGER34V8'],
    paypalButtonIdDevArray: ['', '', '', '', '',''],
    total: '$50',
    totalNum: 50,
    totalNumString: '50',
    totalArray: ['$150', '$366', '$300', '$330', '$330'],
    totalNumArray: [150,366,300,330,330],
    totalNumStringArray: ['150', '366', '300', '330', '330'],
    retired: false
  },
];

export default tournamentVarArray;

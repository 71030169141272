import React from 'react';
import { useFormikContext } from 'formik';

const FormListener = ({ onFormChange, fields }) => {

  const { values, isValid } = useFormikContext();

  React.useEffect(() => {
    onFormChange(values, isValid);
  }, [...fields.map((field) => values[field]), isValid]);

  return null;

};

export default FormListener; 
